.loading-spinner {
    position: fixed; /* Ensures full screen coverage */
    top: 0;
    left: 0;
    width: 100vw; /* Covers entire viewport width */
    height: 100vh; /* Covers entire viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(243, 243, 243, 0.5); /* Light gray background with opacity */
  }
  
  .spinner {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #3498db; /* Adjust color as needed */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }