 .nav {
   height: 80px;
   width: 100%;
   background-color: #ffffff;
   position: fixed;
   top: 0;
   z-index: 10;
   display: flex;
   align-items: center;
   border-bottom: 1px solid #cacaca;
 }

 .nav>.nav-header {
   display: inline;
 }

 .nav>.nav-header>.nav-title {
   display: flex;
   font-size: 22px;
   color: #fff;
   font-weight: 550;
   padding: 10px 10px 10px 10px;
   justify-content: center;
 }

 
 .nav>.nav-btn {
   display: none;
 }
 
 .nav>.nav-links {
   display: flex;
   justify-content: center;
 }

 .nav>.nav-links>ul li a {
   display: block;
   padding: 0 10px;
   color: black;
   line-height: 40px;
   font-size: 18px;
   text-decoration: none;
 }

 #Navdark ul li a{
  color: white;
 }
 #Navdark ul li a:hover{
  color: #74bdf5;
 }


 .nav>.nav-links>ul {
   padding: 0;
   margin-top: 15px;
   list-style: none;
   position: relative;
 }
 
 .nav>.nav-links>ul li {
   display: inline-block;
 }
 
 .nav>.nav-links>ul li a:hover {
   color: #0060AA;
   font-weight: 500;
 }

 .nav>#nav-check {
   display: none;
 }

 .active {
   color: #0060AA !important;
   font-weight: 500;
 }

 .nav .nav-links ul a.icon {
   margin-left: 80px;
   margin-right: 10px;
 }

 .nav .nav-links ul a i {
   background-color: #fff;
   border-radius: 50px;
   padding: 7px;
   margin-left: 5px;
 }

 @media (max-width: 820px) {
   .nav>.nav-btn {
     display: inline-block;
     position: absolute;
     right: 0px;
     top: 0px;
   }

   .nav>.nav-btn>label {
     display: inline-block;
     width: 50px;
     height: 50px;
     padding: 13px;
     margin-top: 12px;
    }
   

   .nav>.nav-btn>label>span {
     display: block;
     width: 25px;
     height: 10px;
     border-top: 3px solid #5dc3f3;
   }

   .nav>.nav-links {
     position: absolute;
     display: block;
     width: 100%;
     background-color: #1e1e1e;
     height: 0px;
     transition: all 0.3s ease-in;
     overflow-y: hidden;
     top: 70px;
     left: 0px;
   }
   /* #navdark .nav>.nav-links{
    background-color: #1d1d1d !important;
    color: #fff;
   } */

   .nav>.nav-links>ul li a {
     display: block;
     width: auto;
   }

   /*   */

   .nav>.nav-links>ul li {
     display: block;
     margin-bottom: 0px;
     padding: 0;
   }

   .nav>.nav-links>ul li a {
    color: white;
     margin-left: 35%;
   }

   .nav .nav-links ul a.icon {
     margin-left: 33%;
   }

   .nav>#nav-check:not(:checked)~.nav-links {
     height: 0px;
   }

   .nav>#nav-check:checked~.nav-links {
     height: calc(100vh - 50px);
     overflow-y: auto;
   }

 }