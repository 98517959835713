.socialLinks{
  padding:5px;
  display: inline;
}
.socialLinks li{
  list-style-type: none;
  margin-left: 2px;
  font-weight: bold ;
  margin-bottom: 5px;
}
img{
  margin-right: 10px;
}