
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;


}

.serviceLinks {
  padding: 5px;
}



.serviceLinks li {
  margin-left: 2px;
  font-weight: bold;
  margin-bottom: 5px;
  list-style-type: none;
  font-weight: bold;
  margin-bottom: 5px;
  list-style-type: none;
}

.footerheading {
  font-size: 20px;  
  font-weight: 600;
  color: #0E94FB;
  line-height: 3;
}

.serviceLinks-hover:hover{
  color: #0E94FB !important;
  font-weight: bold !important;
}