*{
  padding:0px;
  margin: 0px;
  box-sizing: border-box;
  
}
.LogoContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding:2px;
}
.LogoContainer img{
     width:35%;
     height:30%;
     margin-bottom: 10px;
}
.LogoContainer p{
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #6E6E73;
}
@media (max-width:450px){
  .LogoContainer p{
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #6E6E73;
  }
}

.socialLinks li a{
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.serviceLinks li a{
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.socialLinks li a:hover{
  color: #3c9af9;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.serviceLinks li a:hover{
  color: #3c9af9;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.map_pin{
  height: 23px !important;
  width: 23px !important;
}
