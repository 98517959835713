.CopyContainerDark{
    padding:30px;
    text-align: center;
    background-color:#454545;
    color:white


}
.CopyContainerLight{
    padding:30px;
    text-align: center;
    background-color: #e2e2e6;
    color:#454545;

}