*{
    padding:0px;
    margin:0px;
    box-sizing: border-box;
    
}

.nav-routes{
    margin-top: 80px;
}
body{
    font-family: "Inter", sans-serif !important;
}