*{
    padding:0px;
    margin: 0px;
    box-sizing: border-box;
    
  }
.FooterContainer{
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    padding:10%;
}
.FooterContainerSection{
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
}
